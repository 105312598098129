@mixin tree($heightBranch, $left, $bottom,   $widthIndex, $heightIndex,   $stmbhgt, $stmbWdth, $color) {
  left: $left;
  bottom: $bottom;
  .tree-trunk {
    position: absolute;
    &:before {
      border-left: $stmbWdth  solid transparent;
      border-bottom: $stmbhgt solid darken($color, 10);
    }
    &:after {
      border-right: $stmbWdth solid transparent;
      border-bottom: $stmbhgt solid darken($color, 20);
    }
  }
  @for $br from 1 through 4 {
    .tree-branch-#{$br} {
      height: $heightBranch;
      &:before {
        border-left: $br*$widthIndex + $widthIndex*2 solid transparent;
        border-bottom: $br*$heightIndex + $heightIndex*2 solid $color;
      }
      &:after {
        border-right: $br*$widthIndex + $widthIndex*2 solid transparent;
        border-bottom: $br*$heightIndex + $heightIndex*2 solid darken($color, 15);
      }
    }
  }

}
