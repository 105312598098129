@import "util/reset";
@import "util/mixin";

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;500;700&display=swap');

body {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
}
html * {
  box-sizing: border-box;
}


.forest-bathing-bg,
.forest-bathing{
  display: flex;
  min-height: 100vh;
  min-width: 100%;
  width: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  align-items: center;
  justify-content: center;
  background: rgba(255,255,255,.5);
}

.forest-bathing-bg{
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}

.page-wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  .heading{
    text-align: right;
    h1{
      font-size: 38px;
      font-weight: 700;
      margin-top: 20px;
      margin-bottom: 10px;
    }
    h2{
      font-size: 28px;
      font-weight: 300;
    }
  }
}

.tree-wrapper {
  position: relative;
  width: 200px;
  height: 250px;
  overflow: hidden;
}
.tree {
  transform-origin: 0 bottom 0;
  font-size: 0;
  display: block;
  position: absolute;
  width: 200px;
  height: 220px;
  padding-bottom: 40px;
  @include tree(50px, 0, 0, 12px, 20px, 120px, 12px, #789090);
}
.tree-branch {
  height: 50px;
  width: 100%;
  display: inline-block;
  position: relative;
  z-index: 1;
  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
  }
  &:before {
    right: 50%;
  }
  &:after {
    left: 50%;
  }
}
.tree-trunk {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 2;
  &:before,
  &:after {
    width: 2px;
    content: '';
    display: block;
    position: absolute;
    bottom: 0 !important;
  }
  &:before {
    right: 50%;
  }
  &:after {
    left: 50%;
  }
}

.contact{
  font-size: 18px;
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 0;
  padding: 50px;
  p{
    margin-bottom: 10px;
  }
  a{
    background-color: #495959;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
  }
}

@media (max-width : 450px) {
    .page-wrapper{
      flex-flow: column-reverse;
      .heading{
        text-align: center;
      }
    }
}
















